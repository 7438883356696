import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CameraTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Div } from '../../framework';
import { openModal } from '../../store/app.slice';
import { MediaManagerItem } from '../../helper/type';

import './VideoGalleryManager.scss';

const { Paragraph } = Typography;

type Props = {
  value?: MediaManagerItem[];
  onChange?: (mediaItems: MediaManagerItem[]) => void;
};

const maxSize = 25;

const VideoGalleryManager: FC<Props> = ({ value = [], onChange }) => {
  const dispatch = useDispatch();
  const [mediaItems, setMediaItems] = useState<MediaManagerItem[]>([]);
  const [hasFileSizeError, setHasFileSizeError] = useState(false);

  useEffect(() => {
    if (value.length !== mediaItems.length) {
      setMediaItems(value);
    }
  }, [value]);

  const handleAddNewVideo = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files ?? [];

    if (!files.length) return;

    const newMediaItems: MediaManagerItem[] = [];
    setHasFileSizeError(false);

    for (let i = 0; i < files.length; i++) {
      const videoFile = files[i];
      if (videoFile.size / 1024 / 1024 < maxSize) {
        newMediaItems.push({
          url: {
            original: URL.createObjectURL(videoFile),
            thumbnail:
              'https://heycastme-talent-media-prod.s3.ap-southeast-1.amazonaws.com/vid-placeholder.png',
            type: 'video',
          },
          file: {
            original: videoFile,
            thumbnail: videoFile,
          },
          isNew: true,
        });
      } else {
        setHasFileSizeError(true);
      }
    }

    const newItems = [...newMediaItems, ...mediaItems];
    onChange && onChange(newItems);

    setMediaItems(newItems);
  };

  const handleRemoveImage = (index: number) => {
    const newItems = mediaItems.filter((v, i) => index !== i);
    onChange && onChange(newItems);
    setMediaItems(newItems);
  };

  const openGallery = (index = 0) => {
    dispatch(
      openModal({
        modal: 'media-gallery',
        config: {
          context: {
            gallery: mediaItems.map((item) => item.url),
            index,
          },
        },
      })
    );
  };

  const blankSpaces = (mediaItems.length + 1) % 3;

  return (
    <>
      <Div className='component-video-upload-manager'>
        <input
          id='media-upload'
          type='file'
          accept='video/mp4'
          onChange={handleAddNewVideo}
          style={{ display: 'none' }}
        />
        <Div flex className='gallery-preview'>
          <Div className='image-container'>
            <label htmlFor='media-upload' className='upload-media-button'>
              <div>
                <CameraTwoTone />
              </div>
              Add New Videos
            </label>
          </Div>
          {mediaItems.map((item, index) => (
            <Div key={index} className='image-container'>
              <img
                src={item.url.thumbnail}
                onClick={() => openGallery(index)}
              />
              <button
                className='close'
                onClick={() => handleRemoveImage(index)}
              >
                <CloseCircleOutlined />
              </button>
            </Div>
          ))}
          {new Array((blankSpaces === 0 ? 0 : 3) - blankSpaces)
            .fill(1)
            .map((value, index) => (
              <Div className='image-container' key={index}>
                <Div className='image-placeholder' />
              </Div>
            ))}
        </Div>
      </Div>
      <Paragraph
        type='secondary'
        className={`help-message ${hasFileSizeError ? '--error' : ''}`.trim()}
        style={{ marginBottom: 0 }}
      >
        Max size per file: {maxSize}MB
      </Paragraph>
      {hasFileSizeError ? (
        <Paragraph type='secondary' className='help-message --error'>
          Your video exceeds the 25MB limit. Please compress it or send the
          video to yourself via WhatsApp for automatic compression, then save
          and upload the video here.
        </Paragraph>
      ) : null}
      <Paragraph type='secondary' className='help-message'>
        File type: mp4
      </Paragraph>
    </>
  );
};

export default VideoGalleryManager;
